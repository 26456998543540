import { message } from "antd";
import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export const mdCategories = () => {
  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/category-dashboard/", {
      headers: {
        Authorization: undefined,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      errorActions(error, "Error occured");
      return;
    });
};

export const uploadFile = (formData: FormData) => {
  return axios
    .post("https://api-dev2.materialdepot.in/apiV1/project-files/", formData)
    .then((res) => {
      let item_image = res?.data?.file;
      message.success("Files Upload Successfully !");
      return item_image;
    })
    .catch((e) => {
      console.log("e", { e });
      message.error("Something went wrong!");
    });
};

export const getAddressFromPincode = (pincode: any) => {
  return axios
    .get("https://api.postalpincode.in/pincode/" + pincode, {
      headers: {
        Authorization: undefined,
      },
    })
    .then((res) => {
      if (res?.data[0]?.Status == "Success") {
        let address: any = res.data[0]?.PostOffice[0];
        return {
          name: address?.Name,
          address:
            address?.Name + " " + address?.Block + " " + address?.District,
          state: address?.State,
          city: address?.District,
          country: address?.Country,
          pincode: address?.pincode,
        };
      } else {
        return false;
      }
    })
    .catch((error) => {
      errorActions(error, "Error occured");
      return;
    });
};

export const fetchFinanceReport = async (params: any) => {
  console.log("params", params);
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/finance-report/`, {
        params: { ...params },
      })
      .then((res) => {
        // console.log(res.status, "this is the response");

        if (res) {
          const report = res?.data;
          return report;
        }
      })

      .catch((error) => {
        if (error) {
          console.log("error found");
          errorActions(
            error,
            "Error fetching clients. Please check and try again"
          );
          return;
        }
      });
  } catch (error) {}
};

export const fetchFinanceReportMetrics = async (params: any) => {
  console.log("params", params);
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/finance-dashboard-report/`,
        {
          params: { ...params },
        }
      )
      .then((res) => {
        // console.log(res.status, "this is the response");

        if (res) {
          const report = res?.data;
          return report;
        }
      })

      .catch((error) => {
        if (error) {
          console.log("error found");
          errorActions(
            error,
            "Error fetching clients. Please check and try again"
          );
          return;
        }
      });
  } catch (error) {}
};

export const getLatLongFromGooGlLink = async (link: any) => {
  try {
    const response = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/lat-long-from-link/",
      {
        params: { link: link },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const importLoginextTrip = async (tripNames: string[]) => {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/import-loginext-trips/",
      { trip_names: tripNames }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const pushTripToPidge = async (tripId: number | string) => {
  try {
    const response = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/push-to-pidge-from-trip/",
      {
        params: { trip_id: tripId },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const pushTripToPidgeWithName = async (tripName: string) => {
  try {
    const response = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/push-to-pidge-from-trip/",
      {
        params: { trip_name: tripName },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getDeliveries = async (params: any) => {
  try {
    const response = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/po-deliveries/",
      {
        params: { ...params },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const createDelivery = async (data: any) => {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/po-deliveries/",
      data
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const editDelivery = async (data: any) => {
  try {
    const response = await axios.put(
      `https://api-dev2.materialdepot.in/apiV1/po-deliveries/`,
      data
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const uploadImage = async (formData: FormData) => {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/image-upload/",
      formData
    );
    return response.data;
  } catch (e) {
    return null;
  }
};
