import { Button, Drawer, Form, Input, InputNumber, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { IProcurementItem } from "../../../apis/procurement.api";
import { getVendor } from "../../../apis/vendor.api";
import { VendorWarehouseForm } from "../../../pages/vendors/form/warehouse";

export interface ProcurementItemFormProps {
  initData?: Partial<IProcurementItem>;
  onSubmit: (values: Record<string, string | number>) => void;
  vendors: any[];
}

export function ProcurementItemForm({
  initData = {},
  onSubmit,
  vendors,
}: ProcurementItemFormProps) {
  const [selectedVendor, setSelectedVendor] = useState<number | undefined>(
    initData?.selected_vendor_id ?? initData?.vendor?.[0]?.id
  );
  const [showWarehouseDrawer, setShowWarehouseDrawer] = useState(false);

  const [selectedVendorDetails, setSelectedVendorDetails] = useState<
    any | null
  >(null);

  useEffect(() => {
    if (!selectedVendor) {
      setSelectedVendorDetails(null);
      return;
    }

    getVendor(selectedVendor).then((data) => {
      setSelectedVendorDetails(data);
    });
  }, [selectedVendor]);

  return (
    <>
      <Form
        onFinish={onSubmit}
        initialValues={{
          lead_time: initData?.lead_time,
          remarks: initData?.remarks,
          total_stock: initData?.total_stock ?? 0,
          selected_vendor: selectedVendor,
          selected_vendor_ware_house: initData?.selected_vendor_ware_house_id,
        }}
        key={initData?.id}
        layout="inline"
      >
        <Space>
          <Form.Item name="selected_vendor">
            <Select
              placeholder="Vendor"
              style={{ minWidth: 150, maxWidth: 200 }}
              filterOption={(input, option) =>
                (String(option?.label) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={vendors.map((vendor: any) => ({
                label: `${vendor?.name}`,
                value: vendor?.id,
              }))}
              showSearch
              onChange={(value) => setSelectedVendor(value)}
            />
          </Form.Item>
          {selectedVendorDetails &&
          selectedVendorDetails?.warehouses?.length > 0 ? (
            <Form.Item name="selected_vendor_ware_house">
              <Select
                placeholder="Vendor Warehouse"
                style={{ minWidth: 150, maxWidth: 200 }}
                filterOption={(input, option) =>
                  (String(option?.label) ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(selectedVendorDetails?.warehouses ?? []).map(
                  (warehouse: any) => ({
                    label: `${warehouse?.name}`,
                    value: warehouse?.id,
                  })
                )}
                showSearch
              />
            </Form.Item>
          ) : (
            <Button
              type="dashed"
              size="small"
              onClick={() => setShowWarehouseDrawer(true)}
            >
              Add Warehouse
            </Button>
          )}
          {/* {selectedVendor &&
          selectedVendorDetails &&
          selectedVendorDetails?.warehouse?.length === 0 && (
            <Button size="small">Add Warehouse</Button>
          )} */}
          <Form.Item name="lead_time">
            <InputNumber placeholder="Lead Time" />
          </Form.Item>
          <Form.Item name="remarks" style={{ minWidth: 150 }}>
            <Input placeholder="Remarks" />
          </Form.Item>
          {/* <Form.Item name="total_stock">
          <Input placeholder="Total Stock" />
        </Form.Item> */}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {initData?.lead_time ? "Edit" : "Submit"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Drawer
        open={showWarehouseDrawer}
        title="Add Vendor Warehouse"
        onClose={() => {
          setShowWarehouseDrawer(false);
          // Re fetch vendor details
          if (selectedVendor) {
            getVendor(selectedVendor).then((data) => {
              setSelectedVendorDetails(data);
            });
          }
        }}
      >
        <VendorWarehouseForm
          vendor={selectedVendorDetails}
          onClose={() => {
            setShowWarehouseDrawer(false);
            // Re fetch vendor details
            if (selectedVendor) {
              getVendor(selectedVendor).then((data) => {
                setSelectedVendorDetails(data);
              });
            }
          }}
        />
      </Drawer>
    </>
  );
}
