import { Button, message } from "antd";
import { FormEvent } from "react";
import { editDelivery, uploadImage } from "../../../apis/misc.api";

export interface AttachPODDrawerProps {
  delivery: any;
  onClose: () => void;
}

export function AttachPODDrawer({ delivery, onClose }: AttachPODDrawerProps) {
  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.currentTarget);

    const url = await uploadImage(formData);

    if (url && delivery?.id) {
      editDelivery({
        id: delivery.id,
        delivery_proof: url,
      });

      message.success("POD attached successfully");

      // Close the drawer
      onClose();
    } else {
      message.error("Failed to attach POD");
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <input type="file" name="file" />
        </div>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </form>
    </div>
  );
}
