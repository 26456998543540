import { Button, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { createFootfallRecord } from "../../../apis/client.api";

export interface FootfallProps {
  onClose: () => void;
}

export function Footfall({ onClose }: FootfallProps) {
  const [form] = Form.useForm();
  const [branchMembers, setBranchMembers] = useState<any[]>([]);

  useEffect(() => {
    fetchBranchMembers().then((data) => {
      setBranchMembers(data);
    });
  }, []);

  const onSubmit = async (values: any) => {
    await createFootfallRecord(values);
    form.resetFields();
    onClose();
  };

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
            {
              pattern: /^[a-zA-Z ]*$/,
              message: "Please enter a valid name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact"
          name="contact"
          rules={[
            {
              required: true,
              message: "Please enter your contact number",
            },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10 digit contact number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Sales POC"
          name="sales_poc"
          rules={[
            {
              required: true,
              message: "Please select a sales poc",
            },
          ]}
        >
          <Select
            placeholder="Select sales poc"
            showSearch
            options={branchMembers
              ?.filter((member) =>
                [
                  "Vikas",
                  "Padmashri",
                  "Ashish",
                  "Sowbik",
                  "Arfaat",
                  "Dileep Kumar",
                ].includes(member?.user?.f_name)
              )
              ?.map((member) => ({
                label: member?.user?.f_name,
                value: member?.user?.id,
              }))}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* {branchMembers.map((member) => (
              <Select.Option key={member?.user?.id} value={member?.user?.id}>
                {member?.user?.f_name}
              </Select.Option>
            ))} */}
          </Select>
        </Form.Item>
        <Form.Item label="Source" name="source">
          <Select placeholder="Select source">
            <Select.Option value="Online">Online</Select.Option>
            <Select.Option value="Referral">Referral</Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Purpose of visit" name="pov">
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
