import { Drawer } from "antd";

export interface ItemsDrawerProps {
  items: {
    image_url?: string;
    name: string;
    category: string;
    quantity: number;
  }[];
  onClose: () => void;
  open: boolean;
}

export function ItemsDrawer({ items, onClose, open }: ItemsDrawerProps) {
  return (
    <Drawer
      title="Items"
      placement="right"
      closable={true}
      onClose={onClose}
      open={open}
      width={600}
    >
      {items.map((item, index) => (
        <div
          key={index + "-" + item.name}
          className="d-flex border rounded mb-3 gap-4 p-2"
        >
          {item.image_url && (
            <img
              src={item.image_url}
              alt={item.name}
              width={100}
              height={100}
              style={{
                objectFit: "contain",
                maxWidth: 100,
                maxHeight: 100,
                minWidth: 100,
                minHeight: 100,
              }}
            />
          )}
          <div className="flex-grow">
            <div style={{ fontWeight: 600 }}>{item.name}</div>
            <div>
              <strong>Category:</strong> {item.category}
            </div>
            <div>
              <strong>Quantity:</strong> {item.quantity}
            </div>
          </div>
        </div>
      ))}
    </Drawer>
  );
}
