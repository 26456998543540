import {
  FileTextOutlined,
  OrderedListOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { fetchUserDetails } from "../../apis/user.api";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";

const { Footer, Sider } = Layout;

const admin_ids = [
  "989abff0-93c1-443e-9c60-f1d36eaf63fe",
  "56f628c7-43e5-4122-a384-d62e705d8b5e",
  "3775b1f5-ddfb-4f54-95d2-4378b2d50f9d",
];

// const items: MenuProps["items"] = [
//   {
//     key: 1,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/clients"} className="text-decoration-none">
//         Clients
//       </Link>
//     ),
//   },
//   {
//     key: 2,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/pro-forma-invoices"} className="text-decoration-none">
//         PIs
//       </Link>
//     ),
//   },
//   {
//     key: 3,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/orders"} className="text-decoration-none">
//         Orders
//       </Link>
//     ),
//   },
//   {
//     key: 4,
//     icon: React.createElement(OrderedListOutlined),
//     label: (
//       <Link to={"/po-list"} className="text-decoration-none">
//         POs
//       </Link>
//     ),
//   },
//   {
//     key: 5,
//     icon: React.createElement(OrderedListOutlined),
//     label: (
//       <Link to={"/delivery-list"} className="text-decoration-none">
//         Delivery
//       </Link>
//     ),
//   },
//   {
//     key: 6,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/vendors"} className="text-decoration-none">
//         Vendors
//       </Link>
//     ),
//   },
//   {
//     key: 7,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/branch-details"} className="text-decoration-none">
//         Branch Details
//       </Link>
//     ),
//   },
//   {
//     key: 9,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/dashboard"} className="text-decoration-none">
//         Dashboard
//       </Link>
//     ),
//   },
//   {
//     key: 10,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/reports"} className="text-decoration-none">
//         Reports
//       </Link>
//     ),
//   },
//   {
//     key: 11,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/procurement"} className="text-decoration-none">
//         Procurements
//       </Link>
//     ),
//   },
//   {
//     key: 12,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/procurement-delivery"} className="text-decoration-none">
//         Assigned Delivery
//       </Link>
//     ),
//   },
//   {
//     key: 13,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/finance-report"} className="text-decoration-none">
//         Finance Report
//       </Link>
//     ),
//   },
//   {
//     key: 8,
//     icon: React.createElement(UserOutlined),
//     label: (
//       <Link to={"/user-profile"} className="text-decoration-none">
//         User Profile
//       </Link>
//     ),
//   },
// ];

// const admin_items: MenuProps["items"] = [
//   {
//     key: 1,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/clients"} className="text-decoration-none">
//         Clients
//       </Link>
//     ),
//   },
//   {
//     key: 2,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/pro-forma-invoices"} className="text-decoration-none">
//         PIs
//       </Link>
//     ),
//   },
//   {
//     key: 3,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/orders"} className="text-decoration-none">
//         Orders
//       </Link>
//     ),
//   },
//   {
//     key: 4,
//     icon: React.createElement(OrderedListOutlined),
//     label: (
//       <Link to={"/po-list"} className="text-decoration-none">
//         POs
//       </Link>
//     ),
//   },
//   {
//     key: 5,
//     icon: React.createElement(OrderedListOutlined),
//     label: (
//       <Link to={"/delivery-list"} className="text-decoration-none">
//         Delivery
//       </Link>
//     ),
//   },
//   {
//     key: 6,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/vendors"} className="text-decoration-none">
//         Vendors
//       </Link>
//     ),
//   },
//   {
//     key: 14,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/refunds"} className="text-decoration-none">
//         Refunds
//       </Link>
//     ),
//   },
//   {
//     key: 7,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/branch-details"} className="text-decoration-none">
//         Branch Details
//       </Link>
//     ),
//   },
//   {
//     key: 9,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/dashboard"} className="text-decoration-none">
//         Dashboard
//       </Link>
//     ),
//   },
//   {
//     key: 10,
//     icon: React.createElement(FileTextOutlined),
//     label: (
//       <Link to={"/reports"} className="text-decoration-none">
//         Reports
//       </Link>
//     ),
//   },
//   {
//     key: 11,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/procurement"} className="text-decoration-none">
//         Procurements
//       </Link>
//     ),
//   },
//   {
//     key: 12,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/procurement-delivery"} className="text-decoration-none">
//         Assigned Delivery
//       </Link>
//     ),
//   },
//   {
//     key: 13,
//     icon: React.createElement(TableOutlined),
//     label: (
//       <Link to={"/finance-report"} className="text-decoration-none">
//         Finance Report
//       </Link>
//     ),
//   },
//   {
//     key: 8,
//     icon: React.createElement(UserOutlined),
//     label: (
//       <Link to={"/user-profile"} className="text-decoration-none">
//         User Profile
//       </Link>
//     ),
//   },
// ];

const getSelectedKey = () => {
  let currentPath = window.location.pathname;
  if (currentPath === "/clients" || currentPath === "/") {
    return ["1"];
  }
  switch (currentPath) {
    case "/":
      return ["1"];
    case "/clients":
      return ["1"];
    case "/cart":
      return ["16"];
    case "/pro-forma-invoices":
      return ["2"];
    case "/orders":
      return ["3"];
    case "/po-list":
      return ["4"];
    case "/delivery-list":
      return ["5"];
    case "/vendors":
      return ["6"];
    case "/branch-details":
      return ["7"];
    case "/dashboard":
      return ["8"];
    case "/reports":
      return ["9"];
    case "/procurement":
      return ["10"];
    case "/procurement-delivery":
      return ["11"];
    case "/finance-report":
      return ["12"];
    case "/refunds":
      return ["13"];
    case "/user-profile":
      return ["14"];
    case "/finance":
      return ["15"];
    case "/po-margin-dashboard":
      return ["17"];
  }
  return ["0"];
};

const WebsiteLayout = () => {
  const [user, setUser] = useState<any>({});
  const { hasPermission } = usePermissions();
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setUser(res);
      const userRole = localStorage.getItem("user_role");
      console.log("userRole", userRole);
      if (userRole != res?.branch?.user_permission.permission_name) {
        localStorage.setItem(
          "user_role",
          res?.branch?.user_permission.permission_name
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    });
  }, []);

  const getMenuItems = () => {
    let menu_items: MenuProps["items"] = [];
    if (hasPermission("client.read")) {
      menu_items.push({
        key: 1,
        icon: React.createElement(FileTextOutlined),
        label: (
          <Link to={"/clients"} className="text-decoration-none">
            Clients
          </Link>
        ),
      });
    }

    if (hasPermission("pi.tab")) {
      menu_items.push({
        key: 2,
        icon: React.createElement(FileTextOutlined),
        label: (
          <Link to={"/pro-forma-invoices"} className="text-decoration-none">
            PIs
          </Link>
        ),
      });
    }

    if (hasPermission("order.read")) {
      menu_items.push({
        key: 3,
        icon: React.createElement(FileTextOutlined),
        label: (
          <Link to={"/orders"} className="text-decoration-none">
            Orders
          </Link>
        ),
      });
    }

    if (hasPermission("po.read")) {
      menu_items.push({
        key: 4,
        icon: React.createElement(OrderedListOutlined),
        label: (
          <Link to={"/po-list"} className="text-decoration-none">
            POs
          </Link>
        ),
      });
    }

    if (hasPermission("delivery.read")) {
      menu_items.push({
        key: 5,
        icon: React.createElement(OrderedListOutlined),
        label: (
          <Link to={"/delivery-list"} className="text-decoration-none">
            Delivery
          </Link>
        ),
      });
    }

    if (hasPermission("vendor.read")) {
      menu_items.push({
        key: 6,
        icon: React.createElement(FileTextOutlined),
        label: (
          <Link to={"/vendors"} className="text-decoration-none">
            Vendors
          </Link>
        ),
      });
    }
    menu_items.push({
      key: 7,
      icon: React.createElement(FileTextOutlined),
      label: (
        <Link to={"/branch-details"} className="text-decoration-none">
          Branch Details
        </Link>
      ),
    });

    menu_items.push({
      key: "sales-dashboard",
      icon: React.createElement(OrderedListOutlined),
      label: (
        <Link to={"/sales-dashboard"} className="text-decoration-none">
          Sales Dashboard
        </Link>
      ),
    });

    menu_items.push({
      key: 8,
      icon: React.createElement(FileTextOutlined),
      label: (
        <Link to={"/dashboard"} className="text-decoration-none">
          Dashboard
        </Link>
      ),
    });

    menu_items.push({
      key: 9,
      icon: React.createElement(FileTextOutlined),
      label: (
        <Link to={"/reports"} className="text-decoration-none">
          Reports
        </Link>
      ),
    });

    if (hasPermission("po.read")) {
      menu_items.push({
        key: 10,
        icon: React.createElement(TableOutlined),
        label: (
          <Link to={"/procurement"} className="text-decoration-none">
            Procurements
          </Link>
        ),
      });
    }
    if (hasPermission("po.read")) {
      menu_items.push({
        key: 11,
        icon: React.createElement(TableOutlined),
        label: (
          <Link to={"/procurement-delivery"} className="text-decoration-none">
            Assigned Delivery
          </Link>
        ),
      });
    }

    if (hasPermission("finance.read")) {
      menu_items.push({
        key: 12,
        icon: React.createElement(TableOutlined),
        label: (
          <Link to={"/finance-report"} className="text-decoration-none">
            Finance Report
          </Link>
        ),
      });
    }

    if (hasPermission("finance.read")) {
      menu_items.push({
        key: 15,
        icon: React.createElement(TableOutlined),
        label: (
          <Link to={"/finance"} className="text-decoration-none">
            Finance
          </Link>
        ),
      });
    }

    if (hasPermission("finance.read")) {
      menu_items.push({
        key: 17,
        icon: React.createElement(TableOutlined),
        label: (
          <Link to={"/po-margin-dashboard"} className="text-decoration-none">
            PO Margin Dashboard
          </Link>
        ),
      });
    }

    if (hasPermission("refund.read")) {
      menu_items.push({
        key: 13,
        icon: React.createElement(FileTextOutlined),
        label: (
          <Link to={"/refunds"} className="text-decoration-none">
            Refunds
          </Link>
        ),
      });
    }

    menu_items.push({
      key: 14,
      icon: React.createElement(UserOutlined),
      label: (
        <Link to={"/user-profile"} className="text-decoration-none">
          User Profile
        </Link>
      ),
    });

    return menu_items;
  };

  return (
    <>
      <Layout
        hasSider
        style={{ minHeight: "100vh" }}
        // className="d-sm-flex d-none"
      >
        {" "}
        <Sider
          style={{
            overflow: "auto",
            // height: "vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
          className="shadow-sm"
        >
          <Link to={"/"} className="d-flex px-4 py-2 align-items-center">
            <img alt="logo" src="/main_logo.svg" className="w-75" />
          </Link>
          <Menu
            className="fw-bold"
            mode="inline"
            selectedKeys={getSelectedKey()}
            items={getMenuItems()}
            onClick={() => {}}
          />
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 200 }}>
          {!online && (
            <div
              style={{
                backgroundColor: "#E41B17",
                color: "white",
                textAlign: "center",
              }}
              className="p-1"
            >
              You are offline. Please check your internet connection.
            </div>
          )}
          <Outlet />
          <Footer style={{ textAlign: "center" }}>
            Material Depot ©2023 Created by MDEPOT RETAIL TECHNOLOGIES PRIVATE
            LIMITED
          </Footer>
        </Layout>
      </Layout>
      {/* <div className="d-sm-none d-block">
        <ProcurementMobileLayout />
      </div> */}
    </>
  );
};

export default WebsiteLayout;
