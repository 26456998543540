export const permissions: any = {
  "client.create": ["admin", "sales", "pre_sales", "tech"],
  "client.read": ["admin", "sales", "pre_sales", "accounts", "tech"],
  "client.update": ["admin", "sales", "pre_sales", "accounts", "tech"],
  "client.delete": [],

  "pi.create": ["admin", "sales", "pre_sales", "tech"],
  "pi.read": ["admin", "sales", "pre_sales", "accounts", "procurement", "tech"],
  "pi.update": ["admin", "sales", "pre_sales", "tech"],
  "pi.delete": [],
  "pi.tab": ["admin", "sales", "pre_sales", "accounts", "tech"],
  "pi.private_label_name_column": ["admin", "tech"],
  "pi.private_label_brand_name_column": [
    "admin",
    "tech",
    "procurement",
    "delivery",
    "accounts",
  ],
  // "pi.replace_brand_with_private_label": ["procurement"],
  "pi.replace_brand_with_private_label": [],
  "pi.mark_as_order_placed": ["admin"],
  "pi.view_confirmation_link": [],
  "pi.view_vendor_column": ["admin", "procurement", "delivery"],
  "pi.feedback": ["admin", "pre_sales"],

  "order.create": ["admin", "sales", "pre_sales", "tech"],
  "order.read": ["admin", "data", "procurement", "accounts", "tech"],
  "order.update": ["admin"],
  "order.delete": [],
  "order.details": [
    "admin",
    "sales",
    "pre_sales",
    "data",
    "procurement",
    "accounts",
    "tech",
  ],
  "order.payment_info": ["admin", "accounts"],

  "po.create": ["admin", "procurement", "delivery", "tech"],
  "po.read": ["admin", "procurement", "data", "accounts", "delivery", "tech"],
  "po.update": ["admin", "procurement", "delivery", "tech"],
  "po.delete": [],
  "po.public_name": ["accounts"],
  "po.update_dispatch_time": ["admin", "procurement", "tech"],

  "delivery.create": ["admin", "procurement", "delivery", "tech"],
  "delivery.read": ["admin", "procurement", "delivery", "tech"],
  "delivery.update": ["admin", "procurement", "delivery", "tech"],
  "delivery.delete": [],
  "delivery.loginext": ["admin", "delivery", "tech"],

  "vendor.create": ["admin", "procurement", "delivery", "tech"],
  "vendor.read": ["admin", "procurement", "delivery", "accounts", "tech"],
  "vendor.update": ["admin", "procurement", "delivery", "accounts", "tech"],
  "vendor.delete": [],

  "refund.create": ["admin", "sales", "pre_sales", "tech"],
  "refund.read": ["admin", "sales", "pre_sales", "accounts", "tech"],
  "refund.update": ["admin", "sales", "pre_sales", "tech"],
  "refund.delete": [],
  "refund.approve": ["admin"],

  "finance.create": ["admin"],
  "finance.read": ["admin"],
  "finance.update": ["admin"],
  "finance.delete": [],

  "branch_details.create": ["admin"],
  "branch_details.read": [
    "admin",
    "sales",
    "pre_sales",
    "accounts",
    "tech",
    "data",
    "delivery",
  ],
  "branch_details.update": ["admin"],
  "branch_details.delete": [],

  "cart.read": ["admin", "sales", "pre_sales", "tech"],

  "sales.leaderboards": ["admin", "sales", "pre_sales", "tech"],

  "invoice.push": ["admin", "accounts"],
};

const routes = {};

export type PermissionName = keyof typeof permissions;
